<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="7">
        <v-row align="center">
          <v-col cols="12" class="pb-0">
            <p class="mb-0">Tanggal</p>
          </v-col>
          <v-col cols="auto" class="pr-0">
            <v-menu
              ref="menuFromDate"
              v-model="menuFromDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  hide-details
                  outlined
                  class="caption"
                  placeholder="From Date"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  :value="dayjs(startDate).format('DD-MM-YYYY')"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                no-title
                :max="endDate"
                @change="() => {
                  menuFromDate = false
                  fetchListTax();
                }"
                locale="id"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="auto" class="d-none d-sm-flex"><span>-</span></v-col>
          <v-col cols="auto" class="pl-3 pl-sm-0">
            <v-menu
              ref="menuToDate"
              v-model="menuToDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  hide-details
                  class="caption"
                  outlined
                  :value="dayjs(endDate).format('DD-MM-YYYY')"
                  placeholder="To Date"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                no-title
                :min="startDate"
                @change="() => {
                  menuToDate = false
                  fetchListTax();
                }"
                locale="id"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="5">
        <v-row align="center">
          <v-col cols="12" class="pb-0" >
            <p class="mb-0">{{ $_strings.tax.customer }}</p>
          </v-col>
          <v-col cols="12" sm="8">
            <common-auto-complete-items
              type="masterShipper"
              searchName="companyName"
              item-value="id"
              item-text="companyName"
              hide-details
              v-model="companyId"
              dense
              outlined
              class="caption"
              :placeholder="$_strings.tax.filterCustomer"
              :filter="partner3plFilterItems"
              :items="itemsCompany"
              @updateItems="updateListCompanyItems"
              @change="fetchListTax"
            />
          </v-col>
          <v-col cols="auto">
            <v-btn
              class="primary"
              :loading="downloading"
              small
              :disabled="!itemsTax.length"
              @click="download"
            >
              Download
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :loading="isLoading"
          :headers="headers"
          calculate-widths
          :items="itemsTax"
          :server-items-length="taxtListTotalEntry"
          :options.sync="pagination"
          :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
        >
          <template v-slot:[`item.lokasiAsal`]={item}>
            <span>{{ item.lokasiAsal ? item.lokasiAsal : '-' }}</span>
          </template>
          <template v-slot:[`item.lokasiTujuan`]={item}>
            <span>{{ item.lokasiTujuan ? item.lokasiTujuan : '-' }}</span>
          </template>
          <template v-slot:[`footer.page-text`]="props">
            <span>
              Daftar Pajak
              <span v-if="itemsTax.length">
                {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
              </span>
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import {
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';

const { exportCSVFile } = require('../../helper/csvGenerator');

export default {
  name: 'Tax-list',
  data() {
    return {
      isLoading: false,
      companyId: '',
      items: [],
      itemsTax: [],
      itemsCompany: [],
      downloading: false,
      headers: [
        {
          text: this.$_strings.tax.headerTahunPajak,
          value: 'fkTahunPajak',
          class: 'white--text primary text-capitalize',
          width: '120px',
        },
        {
          text: this.$_strings.tax.headerMasaPajak,
          value: 'fkMasaPajak',
          class: 'white--text primary text-capitalize',
          width: '120px',
        },
        {
          text: this.$_strings.tax.headerNPWP,
          value: 'fkNpwp',
          width: '120px',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.tax.headerCustomer,
          value: 'fkNama',
          width: '120px',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.tax.headerAlamatLengkap,
          value: 'fkAlamatLengkap',
          width: '120px',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.order.ORIGIN,
          value: 'lokasiAsal',
          class: 'white--text primary text-capitalize',
          width: '200px',
        },
        {
          text: this.$_strings.order.DESTINATION,
          value: 'lokasiTujuan',
          class: 'white--text primary text-capitalize',
          width: '200px',
        },
        {
          text: this.$_strings.tax.headerNoInvoice,
          value: 'fkReferensi',
          width: '120px',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.tax.headerTotalPengiriman,
          value: 'ofJumlahBarang',
          width: '120px',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.tax.headerNilaiDpp,
          value: 'fkJumlahDpp',
          width: '120px',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.tax.headerNilaiPPN,
          value: 'fkJumlahPpn',
          width: '120px',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.tax.headerNilaiTotal,
          value: 'ofHargaTotal',
          width: '120px',
          class: 'white--text primary text-capitalize',
        },
      ],
      pagination: defaultPagination(),
      partner3plFilterItems: {
        page: 0,
        size: 25,
        totalData: 0,
      },
      taxtListTotalEntry: 0,
      menuFromDate: false,
      startDate: dayjs().format('YYYY-MM-DD'),
      menuToDate: false,
      endDate: dayjs().format('YYYY-MM-DD'),
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        this.fetchListTax();
      },
      deep: true,
    },
  },
  methods: {
    dayjs,
    skipEmptyStringObject,
    updateListCompanyItems(items) {
      this.itemsCompany = [...this.itemsCompany, ...items];
    },
    fetchListTax() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        sort: handleSortBy({ sortBy, sortDesc }),
        fromDate: this.startDate,
        toDate: this.endDate,
        companyId: this.companyId,
      };
      this.isLoading = true;
      this.$_services.tax.fetchTax(this.skipEmptyStringObject(filters))
        .then((result) => {
          this.itemsTax = result.data.contents;
          this.taxtListTotalEntry = result.data.totalData;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async download() {
      try {
        this.downloading = true;
        const filters = {
          fromDate: this.startDate,
          toDate: this.endDate,
          companyId: this.companyId,
        };
        if (this.companyId) {
          filters.companyId = this.companyId;
        } else {
          delete filters.companyId;
        }
        const result = await this.$_services.tax.exportTax(filters);
        exportCSVFile(null, [[result]], 'Laporan Pajak (e-Faktur)');
      } finally {
        this.downloading = false;
      }
    },

  },
};
</script>
