var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0"},[_vm._v("Tanggal")])]),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"auto"}},[_c('v-menu',{ref:"menuFromDate",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"caption",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":"From Date","prepend-inner-icon":"mdi-calendar","readonly":"","value":_vm.dayjs(_vm.startDate).format('DD-MM-YYYY')}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFromDate),callback:function ($$v) {_vm.menuFromDate=$$v},expression:"menuFromDate"}},[_c('v-date-picker',{attrs:{"no-title":"","max":_vm.endDate,"locale":"id"},on:{"change":function () {
                _vm.menuFromDate = false
                _vm.fetchListTax();
              }},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',{staticClass:"d-none d-sm-flex",attrs:{"cols":"12","sm":"auto"}},[_c('span',[_vm._v("-")])]),_c('v-col',{staticClass:"pl-3 pl-sm-0",attrs:{"cols":"auto"}},[_c('v-menu',{ref:"menuToDate",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"caption",attrs:{"dense":"","hide-details":"","outlined":"","value":_vm.dayjs(_vm.endDate).format('DD-MM-YYYY'),"placeholder":"To Date","prepend-inner-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuToDate),callback:function ($$v) {_vm.menuToDate=$$v},expression:"menuToDate"}},[_c('v-date-picker',{attrs:{"no-title":"","min":_vm.startDate,"locale":"id"},on:{"change":function () {
                _vm.menuToDate = false
                _vm.fetchListTax();
              }},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$_strings.tax.customer))])]),_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('common-auto-complete-items',{staticClass:"caption",attrs:{"type":"masterShipper","searchName":"companyName","item-value":"id","item-text":"companyName","hide-details":"","dense":"","outlined":"","placeholder":_vm.$_strings.tax.filterCustomer,"filter":_vm.partner3plFilterItems,"items":_vm.itemsCompany},on:{"updateItems":_vm.updateListCompanyItems,"change":_vm.fetchListTax},model:{value:(_vm.companyId),callback:function ($$v) {_vm.companyId=$$v},expression:"companyId"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"primary",attrs:{"loading":_vm.downloading,"small":"","disabled":!_vm.itemsTax.length},on:{"click":_vm.download}},[_vm._v(" Download ")])],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"calculate-widths":"","items":_vm.itemsTax,"server-items-length":_vm.taxtListTotalEntry,"options":_vm.pagination,"footer-props":{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': _vm.$_item_per_page}},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.lokasiAsal",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.lokasiAsal ? item.lokasiAsal : '-'))])]}},{key:"item.lokasiTujuan",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.lokasiTujuan ? item.lokasiTujuan : '-'))])]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" Daftar Pajak "),(_vm.itemsTax.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }